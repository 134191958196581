<template>
  <div>
        <el-card >
          <el-table :data="orderList">
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="serviceProvider" :label="$t('msg.rate.quoteFrom')"></el-table-column>
            <el-table-column prop="client" :label="$t('msg.rate.quoteTo')" ></el-table-column>
            <el-table-column prop="orderSector" :label="$t('msg.rate.quoteType')" ></el-table-column>
            <el-table-column prop="csopName" :label="$t('msg.rate.csopName')" ></el-table-column>
            <el-table-column prop="createTime" :label="$t('msg.rate.createTime')" ></el-table-column>
            <el-table-column prop="payTerm" :label="$t('msg.rate.payTerm')" ></el-table-column>
            <el-table-column prop="shipStatus" :label="$t('msg.account.shipStatus')" ></el-table-column>
            <el-table-column prop="billStatus" :label="$t('msg.account.billStatus')" ></el-table-column>
            <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px">
              <template #default="{row}">
                <el-button type="primary" size="small" @click="getDetail(row)">{{ $t('msg.address.check') }}</el-button>
                <el-button type="danger" size="small" @click="delOrder(row)" :disabled="(row.billStatus!=='NA'&& row.billStatus!=='AR')||row.shipStatus==='DrayBatch'">Del</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
export default defineComponent({
  name: 'orders',
  setup() {
    const router = useRouter()
    const orderList = ref()
    const curUser = localStorage.getItem('curUser')
    onMounted(() => {
      getOrderList()
    })
    const getOrderList = () => {
      const param = 'curName=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/myOrderList'
      axios.post(reqURL, param).then((response) => {
        orderList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getDetail = row => {
      if (row.shipStatus === 'DrayBatch') {
        const myParam = 'myNewId=' + row.id + '&csopName=' + row.csopName
        router.push(`/dray/batchOrderList/${myParam}`)
      } else {
        /* const myParam = 'myNewId=' + row.id + '&orderSector=' + row.orderSector + '&csopName=' + row.csopName
        router.push(`/Billing/drayOrderDetail/${myParam}`) */
        const refId = row.shipStatus
        router.push(`/dray/detail/${refId}`)
      }
    }
    const delOrder = row => {
      const param = 'rmId=' + row.id + '&csopName=' + row.csopName + '&clientCode=' + row.clientCode + '&primeKey=' + row.shipStatus
      const reqURL = process.env.VUE_APP_BASE + '/delOrderByRefId'
      axios.post(reqURL, param).then((response) => {
        alert(response.data)
        getOrderList()
      }).catch((response) => {
        console.log(response)
      })
    }
    return { orderList, getOrderList, getDetail, delOrder }
  }
})
</script>
